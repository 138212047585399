#main {
  background-color: $jobs-bg;

  .header-container {
    background-color: $jobs-header-container-bg !important;
  }

  .jobsHeaderImage {
    background-image: url("../images/banner.jpg");
    background-color: $jobs-header-container-bg !important;
    background-size: contain;
    background-position: right;
    background-repeat: no-repeat;
    overflow: hidden;
    height: 170px;
  }

  /* search form mobile to push the form below the nav */
  @media (max-width: 991.98px) {
    .mobileMargin {
      margin-top: 80px;
    }
  }

  /*Search componenet */
  #pageTitle {
    background-color: $jobs-search-bg !important;
    color: $white;

    .jobs-search-btn {
      background-color: $btn-primary-color;
      color: $white;

      .bi {
        color: $white;
      }
    }
  }

  #jobCounts {
    h5 {
      color: #ffffff !important;
    }

    .box {
      background-color: #000000;

      hr {
        color: #ffffff;
        border: .05rem solid #ffffff !important;
        opacity: 1;
      }

      .newJobsIcon {
        color: $newJobsIcon-color !important;
        padding: 5px;

      }

      .totalJobsIcon {
        color: $totalJobsIcon-color !important;
        padding: 5px;
      }
    }

    .jobsPageImage {
      background-image: url("../images/page-bg2.jpg");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: right top;
    }

    h4 {
      color: #f6b341;
      font-weight: 900;

      small {
        font-size: 1rem;
        color: #ffffff;
        font-weight: 300;
      }
    }
  }

  #map {
    border-radius: 5px !important;
    border: 0rem solid transparent !important;
    overflow: hidden;
  }
}


#jobListings {

  #jobResults {
    #jobsKey {
      background-color: $jobs-search-key-bg;
      padding: 1em;

      h6 {
        color: #666666;
      }

      small {
        color: #666666 !important;
      }
    }

    .jobIcon {
      width: 32px;
      height: 32px;
      border-radius: 5px;
      border: 0px solid;
      /*background-color: #3478f6;*/
      color: $newJobsIcon-color;
      text-align: center;
      vertical-align: middle;
      margin-right: 10px;
      margin-left: 5px;

      .bi::before {
        line-height: 2 !important;
      }
    }

    .jobHighNeedsIcon {
      width: 32px;
      height: 32px;
      border-radius: 5px;
      border: 0px solid;
      /*background-color: #f6b341;*/
      color: #f6b341;
      text-align: center;
      vertical-align: middle;
      margin-right: 10px;
      margin-left: 5px;

      .bi::before {
        line-height: 2 !important;
      }
    }

    .jobNewJobsIcon {
      width: 32px;
      height: 32px;
      border-radius: 5px;
      border: 0px solid;
      /*background-color: #0de212;*/
      color: $totalJobsIcon-color;
      text-align: center;
      vertical-align: middle;
      margin-right: 10px;
      margin-left: 5px;

      .bi::before {
        line-height: 2 !important;
      }

      .totalJobsIcon {
        color: $newJobsIcon-color !important;
        padding: 5px;
      }

      .newJobsIcon {
        color: $totalJobsIcon-color !important;
        padding: 5px;
      }

    }

    //JOB CARDS SECTION
    .card {
      background-color: transparent !important;
      color: $card-font-color;
      border-color: $card-border-color;

      .card-top {
        background-color: $card-top-bg-color;
        border-radius: 0px;
        overflow: hidden;

        .bi {
          color: $card-bi-color !important;
        }

        h6 {
          color: $card-h6-color;
        }
      }

      p {
        color: $card-font-color;
      }

      .card-body {
        background-color: transparent !important;
        color: $card-font-color;
      }

      .totalJobsIcon {
        color: $newJobsIcon-color !important;
        padding: 5px;
      }

      .newJobsIcon {
        color: $totalJobsIcon-color !important;
        padding: 5px;
      }

      .card-btn{
        border: solid 1px $btn-primary-color;
        background-color: $btn-primary-color;
        color: $white;
      }

      .card-btn:hover {
        background-color: $white;
        color: $btn-primary-color;
      }


      //@media (max-width: 991.98px) {
      //  .btn-mobile-details {
      //    background-color: $btn-primary-color !important;
      //    color: $white !important;
      //    width: 50%;
      //  }
      //  .btn-mobile-details:hover {
      //    background-color: $white;
      //    color: $btn-primary-color;
      //  }
      //    .btn {
      //    border-color: $btn-primary-color;
      //    width: 50%;
      //  }
      //}
    }
  }

  .modal-title {
    color: $accent-color-1
  }

}

