#navTop {
  z-index: 9999;

  .navbar-scroll {
    transition: 500ms ease;
    background: transparent;
  }

  .navbar-scroll.scrolled {
    background: $navbar-bg-scrolled;
    border-bottom: 1px solid $nav-link-scrolled-color;
    color: $nav-link-scrolled-color;

    .nav-item {
      color: $nav-link-scrolled-color !important;
    }

    .nav-item:link {
      color: #ffffff;
    }
  }

  //.navbar-dark {
  //  background-color: $navbar-bg;
  //}
  @media (min-width: 992px) {
    //.navbar-dark {
    //  background-color: none !important;
    //}
  }
  //.bg-dark {
  //  background-color: #000000 !important;
  //}

  .navbar {
    background-color: $navbar-bg-color;
    min-height: 75px;

    .navbar-brand {
      img {
        max-width: 250px;
      }
    }

    .navbar-toggler {
      //hamburger-icon
      .bi {
        color: $hamburger-icon-color;
      }
    }

    .navbar-toggler:focus,
    .navbar-toggler:focus-visible {
      color: $hamburger-icon-color;
    }

    .nav-item {
      font-family: 'Montserrat', sans-serif;
      font-weight: 600;
      text-transform: uppercase;
      color: $nav-link-color;

      .nav-link {
        color: $nav-link-color;

        .bi, .far {
          color: $accent-color-2 !important
        }
      }
    }

    .dropdown-toggle::after {
      border-top: .3em solid $accent-color-2;
    }

    //DROPDOWN ITEM SUB MENU
    .dropdown-menu {
      li {
        .dropdown-item {
          color: $nav-dropdown-link-color;
        }
      }
    }
  }
}


