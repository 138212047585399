.blogWrapper {
  .background-color: $blog-background-color;
  /* blog index page cards */
  .album {
    .card {
      background-color: $blog-card-background;
      img {
        width: 100%;
        height: auto;
      }
      .card-body {
        color: $blog-text-color;
        h4 {
          color: $blog-headline-color;
        }
      }
    }
  }
  /* blog entry page */
  .blogContainer {
    .background-color: $blog-entry-background-color;
    .blogContent {
      color: $blog-text-color;
      h1, h2, h3, h4 {
        color: $blog-headline-color;
        text-align: center;
      }
      p {
        color: $blog-text-color;
      }
    }
    .blogImage {
      img {
        width: 100%;
        height: auto;
      }
    }
  }
}