//DEFAULT WHITE AND BLACK
$white:#ffffff;
$black: #000000;

//accent colors
$accent-color-1:#DE7542;
$accent-color-2:#D3313A;

//NAVIGATION
$navbar-bg-color:$black;
$navbar-bg-scrolled: #666666;
$hamburger-icon-color: #DE7542;
$nav-link-color: $white;
$nav-dropdown-link-color: #666666;
$nav-link-scrolled-color:#ffffff;

//HEADERS ON PAGES
$header-bg:#000000;
$header-h1:#D3313A;
$header-h4:#ffffff;
$header-button-bg:#DE7542;
$header-button-bg-hover: #ee8f65;
$header-button-color:#ffffff;
$header-icon-color:#DE7542;

//Article Variables
$article-bg:#EDEDED;
$article-bg-alt:$black;
$article-text-color:#ffffff;

//BLOG
$blog-headline-color:#393939;
$blog-text-color:#393939;
$blog-card-background: #ffffff;
$blog-background-color: #ffffff;
$blog-entry-background-color: #ffffff;

//JOB SEARCH CARD SECTION
$content-box-radius:10px !important;
$content-box-border:1px solid #6c757d;
$content-box-shadow:0 .5rem 1rem rgba(0,0,0,.15)!important;
$content-box-icon-color:#D3313A;
$content-box-h3-color:#999999;
$content-box-p-color:#ffffff;
$content-box-bg-color: none;
$content-box-button-bg-color:#DE7542;
$content-box-button-bg-color-hover:#ee8f65;
$content-box-button-text-color: #ffffff;
$content-box-button-icon-color: #ffffff;

//SOCIAL PROOF
$social-proof-bg:#212529;
$social-proof-color:$accent-color-1;

//CTA
$cta-bg:#000000;
$cta-color: #6c757d;
$cta-h1-color: $header-h1;

//FORMS
$form-bg:none;
$form-header-color:$accent-color-2;
$form-color:#ffffff;
$form-submit-bg: $accent-color-1;
$form-submit-bg-hover: $accent-color-2;
$form-submit-text-color: #ffffff;

//FOOTER
$footer-bg:#cccccc;
$footer-h3:$accent-color-2;
$footer-nav-link-color:$black;
$footer-nav-link-color-hover: #666666;

//Button Colors
$btn-primary-color: $accent-color-1;
$btn-primary-color-hover: $accent-color-2;
$btn-secondary-color: $accent-color-2;
$btn-secondary-color-hover: $accent-color-1;

//Icon Colors
$icon-color: #de7542;
$box-icon-color:#6c757d;

//Backgrounds
$background-color:#000000;
$background-gradient:linear-gradient(180deg, #000000 0%, #212529 100%);
$background-filter:progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#212529",GradientType=1);
$background-dark:  rgba(33,37,41, 1);

///////////////////////////////////
/* JOBS SECTIONS AND CARDS BELOW */
//////////////////////////////////
$jobs-bg:$black;
$jobs-search-bg:none;
$jobs-header-container-bg:$black;
$jobs-search-key-bg:none;

//JOBS SECTIONS VARIABLES
$newJobsIcon-color:#3478f6;
$totalJobsIcon-color:#0de212;
$job-font-color:#ffffff;

//Job CARDS
$card-font-color: $white;
$card-border-color: #6c757d!important;
$card-top-bg-color:#333333;
$card-h6-color:$white;
$card-bi-color:$accent-color-1;