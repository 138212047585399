.homeForm {
  .homeForm__inner {
    background: $form-bg;

    label {
      color: $form-color;
    }

    .fui-input, .fui-select {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      background: rgba(0, 0, 0, 0.8) !important;
      color: $white !important;
    }

    .fui-submit {
      background-color: $form-submit-bg !important;
      border-color: $form-submit-bg;
      color: $form-submit-text-color;
    }

    .fui-label, .fui-legend {
      color: $form-color;
    }

    .fui-checkbox input:checked + label:before, .fui-radio input:checked + label:before {
      background-color: $form-submit-bg;
      border-color: $form-submit-bg;
    }

    .fui-field {
      flex: 1 !important;
    }
  }
}

//SIDEBAR SHORT FORM
.pageForm {
  /* formie */
  background-color: $form-bg;

  h3{
    color: $form-header-color !important;
  }

  .fui-input, .fui-select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: rgba(0, 0, 0, 0.8) !important;
    color: $white !important;
  }

  .fui-submit {
    background-color: $form-submit-bg !important;
    color: $form-submit-text-color !important;
    border-color: $form-submit-bg;
  }

  .fui-label, .fui-legend {
    color: $form-color;
  }

  .fui-checkbox input:checked + label:before, .fui-radio input:checked + label:before {
    background-color: $form-submit-bg;
    border-color: $form-submit-bg;
    color: $white !important;
  }
  .fui-color {
    color: $white;
  }
}
