body {
  background-color: #000000 !important;
  //background-color: $background-color !important;
}
/* home page */
#home {
  header {
    background-color: $header-bg;
    .header-image {
      background-image: url("../images/home-bg2.jpg");
      height: 500px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center top;

    }
    @media screen and (max-width: 1550px) {
      .header-image {
        height: 425px;
      }
    }
    @media screen and (max-width: 1200px) {
      .header-image {
        height: 360px;
      }
    }

    .headline {
      // top: 150px;
      // position: absolute;


      h1 {
        font-family: 'Montserrat', sans-serif;
        font-weight: 800;
        font-style: italic;
        color: $header-h1;
      }
      h4{
        color: $header-h4;
      }
      .btn{
        background-color: $header-button-bg;
        color: $header-button-color;
      }
      .btn:hover{
        background-color: $header-button-bg-hover;
      }
    }

  }

  article{
    //background: $article-bg;
    background: $background-gradient;
    filter: $background-filter;
       p {
         color: $article-text-color;

       }
      .box {
        background-color: black;
      }

    //Box styles on the home page
    .content-box{
      background: $content-box-bg-color;
      border-radius: $content-box-radius;
      border: $content-box-border;
      box-shadow: $content-box-shadow;
      //color of icon in the boxes in the homepage
      .bi{
        color: $content-box-icon-color;
      }
      h3{
        color: $content-box-h3-color;
      }
      p{
        color: $content-box-p-color;
      }
      .btn{
        background: $content-box-button-bg-color;
        color: $content-box-button-text-color;
        .bi{
          color:$content-box-button-icon-color;
        }
      }
    }
  }

  .video {
    margin-top: -100px !important;
  }

}

/* Social Proof */
#socialProof {
  background: $social-proof-bg;
  img {
    width: 100%;
    height: 100%;
  }
  .line {
    height: 1px;
    background-color: #de9947;
    opacity: 1;
  }
  h1, h2, h3, h4, h5, p {
    color: $social-proof-color;
  }
}

/* Call to Action */
//this is the container that houses the form in the homepage
//Please see the form.scss file for the styles for the home page form
#callToAction{
background: $cta-bg;
  h1{
    color: $cta-h1-color;
  }
}

/* footer */

footer {
/*  border-top: 1px solid #8b252f;*/
  background: $footer-bg;
  .logo img {
    width: 100%;
    max-width: 250px;
    height: auto;
  }
  h3{
    color: $footer-h3;
  }
  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    li{
      color: $footer-nav-link-color;
      a{
        color: $footer-nav-link-color;
      }
    }

    .nav-link {
      padding: 0;
      color: $footer-nav-link-color !important;
      a{
        color: $footer-nav-link-color !important;
      }
    }
    .nav-link:hover, .nav-link:active, a:hover, a:active {
      color: $footer-nav-link-color-hover !important;
    }

  }

}
