@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,600;0,800;1,400;1,600;1,800&display=swap');
body {
  padding-top: 75px;
}
/* fonts */
p {
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  color: $black;
}

h2 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700 !important;
  color: $accent-color-2;
  font-style: italic;
}

h3 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  color: $accent-color-2;
  font-style: italic;
}

h4 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  color: $white;
  font-style: italic;
}

h1 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 900 !important;
  color: $accent-color-1;
  font-style: italic;
}
@media (max-width: 991.98px) {
  h1 {
    font-size: 2rem !important;
  }
}

li {
  color: $black;
}

a {
  //color: $white;
  text-decoration: none;
}
a:hover {
  //color: $primary;
}


/* buttons */
.btn-fullwidth {
  width: 100%;
}

.btn {
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
}

.btn-primary {
  background-color: $btn-primary-color;
  color: $white;
  border: none;
}
.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active {
  background-color: $btn-primary-color-hover;
  color: $white;
}

.btn-secondary {
  background-color: $btn-secondary-color;
  color: $white;
  border: none;
}
.btn-secondary:hover,
.btn-secondary:focus,
.btn-secondary:active {
  background-color: $btn-secondary-color-hover;
  color: $white;
}

.btn-link {
  color: $btn-primary-color;
}
.btn-link:hover {
  color: $btn-primary-color-hover;
}

.rounded {
  border-radius: 10px !important;
  overflow: hidden;
}

.rounded-top {
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
}

/* image boxes */

.div-skew {
  transform: skewX(-20deg);
}
.div-skew:after {
  transform: skewX(20deg);
}

#box-skew {
  transform: skewX(-20deg);
  border-radius: 10px;
  width: 300px;
  height: 300px;
  overflow: hidden;
  margin: 40px auto;
  border: 2px solid orange;
  position: relative;
}

#box-skew:before {
  content: "";
  position: absolute;
  width: 425px;
  height: 300px;
  left: -75px;
  z-index: -1;
  //background: url("../images/gettyimages-1184187261-170667a.jpg");
  transform: skewX(20deg);
}

/* pagination */

#pagination {
  .page-link {
    background-color: black;
    color: $white;
    border-color: #6e747c
  }
  .page-link:hover {
    background-color: #3a3b40;
    color: $white;
  }
  .page-item.active .page-link {
    z-index: 3;
    color: $white;
    background-color: $btn-primary-color;
    border-color: $btn-primary-color;

  }
}