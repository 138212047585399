/* JOBS SECTIONS AND CARDS BELOW */
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,600;0,800;1,400;1,600;1,800&display=swap");
body {
  padding-top: 75px;
}

/* fonts */
p {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  color: #000000;
}

h2 {
  font-family: "Montserrat", sans-serif;
  font-weight: 700 !important;
  color: #D3313A;
  font-style: italic;
}

h3 {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  color: #D3313A;
  font-style: italic;
}

h4 {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  color: #ffffff;
  font-style: italic;
}

h1 {
  font-family: "Montserrat", sans-serif;
  font-weight: 900 !important;
  color: #DE7542;
  font-style: italic;
}

@media (max-width: 991.98px) {
  h1 {
    font-size: 2rem !important;
  }
}
li {
  color: #000000;
}

a {
  text-decoration: none;
}

/* buttons */
.btn-fullwidth {
  width: 100%;
}

.btn {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
}

.btn-primary {
  background-color: #DE7542;
  color: #ffffff;
  border: none;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active {
  background-color: #D3313A;
  color: #ffffff;
}

.btn-secondary {
  background-color: #D3313A;
  color: #ffffff;
  border: none;
}

.btn-secondary:hover,
.btn-secondary:focus,
.btn-secondary:active {
  background-color: #DE7542;
  color: #ffffff;
}

.btn-link {
  color: #DE7542;
}

.btn-link:hover {
  color: #D3313A;
}

.rounded {
  border-radius: 10px !important;
  overflow: hidden;
}

.rounded-top {
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
}

/* image boxes */
.div-skew {
  transform: skewX(-20deg);
}

.div-skew:after {
  transform: skewX(20deg);
}

#box-skew {
  transform: skewX(-20deg);
  border-radius: 10px;
  width: 300px;
  height: 300px;
  overflow: hidden;
  margin: 40px auto;
  border: 2px solid orange;
  position: relative;
}

#box-skew:before {
  content: "";
  position: absolute;
  width: 425px;
  height: 300px;
  left: -75px;
  z-index: -1;
  transform: skewX(20deg);
}

/* pagination */
#pagination .page-link {
  background-color: black;
  color: #ffffff;
  border-color: #6e747c;
}
#pagination .page-link:hover {
  background-color: #3a3b40;
  color: #ffffff;
}
#pagination .page-item.active .page-link {
  z-index: 3;
  color: #ffffff;
  background-color: #DE7542;
  border-color: #DE7542;
}

#navTop {
  z-index: 9999;
}
#navTop .navbar-scroll {
  transition: 500ms ease;
  background: transparent;
}
#navTop .navbar-scroll.scrolled {
  background: #666666;
  border-bottom: 1px solid #ffffff;
  color: #ffffff;
}
#navTop .navbar-scroll.scrolled .nav-item {
  color: #ffffff !important;
}
#navTop .navbar-scroll.scrolled .nav-item:link {
  color: #ffffff;
}
#navTop .navbar {
  background-color: #000000;
  min-height: 75px;
}
#navTop .navbar .navbar-brand img {
  max-width: 250px;
}
#navTop .navbar .navbar-toggler .bi {
  color: #DE7542;
}
#navTop .navbar .navbar-toggler:focus,
#navTop .navbar .navbar-toggler:focus-visible {
  color: #DE7542;
}
#navTop .navbar .nav-item {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  text-transform: uppercase;
  color: #ffffff;
}
#navTop .navbar .nav-item .nav-link {
  color: #ffffff;
}
#navTop .navbar .nav-item .nav-link .bi, #navTop .navbar .nav-item .nav-link .far {
  color: #D3313A !important;
}
#navTop .navbar .dropdown-toggle::after {
  border-top: 0.3em solid #D3313A;
}
#navTop .navbar .dropdown-menu li .dropdown-item {
  color: #666666;
}

body {
  background-color: #000000 !important;
}

/* home page */
#home header {
  background-color: #000000;
}
#home header .header-image {
  background-image: url("../images/home-bg2.jpg");
  height: 500px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center top;
}
@media screen and (max-width: 1550px) {
  #home header .header-image {
    height: 425px;
  }
}
@media screen and (max-width: 1200px) {
  #home header .header-image {
    height: 360px;
  }
}
#home header .headline h1 {
  font-family: "Montserrat", sans-serif;
  font-weight: 800;
  font-style: italic;
  color: #D3313A;
}
#home header .headline h4 {
  color: #ffffff;
}
#home header .headline .btn {
  background-color: #DE7542;
  color: #ffffff;
}
#home header .headline .btn:hover {
  background-color: #ee8f65;
}
#home article {
  background: linear-gradient(180deg, #000000 0%, #212529 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#212529",GradientType=1);
}
#home article p {
  color: #ffffff;
}
#home article .box {
  background-color: black;
}
#home article .content-box {
  background: none;
  border-radius: 10px !important;
  border: 1px solid #6c757d;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}
#home article .content-box .bi {
  color: #D3313A;
}
#home article .content-box h3 {
  color: #999999;
}
#home article .content-box p {
  color: #ffffff;
}
#home article .content-box .btn {
  background: #DE7542;
  color: #ffffff;
}
#home article .content-box .btn .bi {
  color: #ffffff;
}
#home .video {
  margin-top: -100px !important;
}

/* Social Proof */
#socialProof {
  background: #212529;
}
#socialProof img {
  width: 100%;
  height: 100%;
}
#socialProof .line {
  height: 1px;
  background-color: #de9947;
  opacity: 1;
}
#socialProof h1, #socialProof h2, #socialProof h3, #socialProof h4, #socialProof h5, #socialProof p {
  color: #DE7542;
}

/* Call to Action */
#callToAction {
  background: #000000;
}
#callToAction h1 {
  color: #D3313A;
}

/* footer */
footer {
  /*  border-top: 1px solid #8b252f;*/
  background: #cccccc;
}
footer .logo img {
  width: 100%;
  max-width: 250px;
  height: auto;
}
footer h3 {
  color: #D3313A;
}
footer ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
footer ul li {
  color: #000000;
}
footer ul li a {
  color: #000000;
}
footer ul .nav-link {
  padding: 0;
  color: #000000 !important;
}
footer ul .nav-link a {
  color: #000000 !important;
}
footer ul .nav-link:hover, footer ul .nav-link:active, footer ul a:hover, footer ul a:active {
  color: #666666 !important;
}

/* landing page dark */
#landingPageDark header {
  background-color: #000000;
}
#landingPageDark header .header-image {
  background-image: url("../images/page-bg2.jpg");
  min-height: 300px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right center;
}
@media screen and (max-width: 1200px) {
  #landingPageDark header .header-image {
    min-height: 285px;
  }
}
@media screen and (max-width: 992px) {
  #landingPageDark header .header-image {
    min-height: 210px;
  }
}
#landingPageDark header .headline h1 {
  font-family: "Montserrat", sans-serif;
  font-weight: 800;
  font-style: italic;
  color: #D3313A;
}
#landingPageDark header .headline h4 {
  color: #ffffff;
}
#landingPageDark header .headline h4 .btn-link {
  background-color: #000000 !important;
  color: #DE7542;
}
#landingPageDark header .headline h4 .btn-link .bi {
  color: #DE7542;
}
#landingPageDark header .headline .play-btn {
  background-color: #000000 !important;
  color: #DE7542 !important;
}
#landingPageDark header .headline .btn {
  background-color: #DE7542;
  color: #ffffff;
}
#landingPageDark header .headline .btn-reverse {
  background: inherit !important;
}
@media screen and (max-width: 992px) {
  #landingPageDark header {
    margin-top: 70px;
  }
}
#landingPageDark article {
  background: linear-gradient(180deg, #000000 0%, #212529 100%);
  color: #ffffff;
}
#landingPageDark article ul {
  color: #ffffff;
}
#landingPageDark article li {
  color: #ffffff;
}
#landingPageDark article h3 {
  color: #D3313A;
}
#landingPageDark article p {
  color: #ffffff;
}
#landingPageDark article #jobIds p {
  color: #ffffff;
  margin-bottom: 0px;
}

#main {
  background-color: #000000;
  /* search form mobile to push the form below the nav */
  /*Search componenet */
}
#main .header-container {
  background-color: #000000 !important;
}
#main .jobsHeaderImage {
  background-image: url("../images/banner.jpg");
  background-color: #000000 !important;
  background-size: contain;
  background-position: right;
  background-repeat: no-repeat;
  overflow: hidden;
  height: 170px;
}
@media (max-width: 991.98px) {
  #main .mobileMargin {
    margin-top: 80px;
  }
}
#main #pageTitle {
  background-color: none !important;
  color: #ffffff;
}
#main #pageTitle .jobs-search-btn {
  background-color: #DE7542;
  color: #ffffff;
}
#main #pageTitle .jobs-search-btn .bi {
  color: #ffffff;
}
#main #jobCounts h5 {
  color: #ffffff !important;
}
#main #jobCounts .box {
  background-color: #000000;
}
#main #jobCounts .box hr {
  color: #ffffff;
  border: 0.05rem solid #ffffff !important;
  opacity: 1;
}
#main #jobCounts .box .newJobsIcon {
  color: #3478f6 !important;
  padding: 5px;
}
#main #jobCounts .box .totalJobsIcon {
  color: #0de212 !important;
  padding: 5px;
}
#main #jobCounts .jobsPageImage {
  background-image: url("../images/page-bg2.jpg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right top;
}
#main #jobCounts h4 {
  color: #f6b341;
  font-weight: 900;
}
#main #jobCounts h4 small {
  font-size: 1rem;
  color: #ffffff;
  font-weight: 300;
}
#main #map {
  border-radius: 5px !important;
  border: 0rem solid transparent !important;
  overflow: hidden;
}

#jobListings #jobResults #jobsKey {
  background-color: none;
  padding: 1em;
}
#jobListings #jobResults #jobsKey h6 {
  color: #666666;
}
#jobListings #jobResults #jobsKey small {
  color: #666666 !important;
}
#jobListings #jobResults .jobIcon {
  width: 32px;
  height: 32px;
  border-radius: 5px;
  border: 0px solid;
  /*background-color: #3478f6;*/
  color: #3478f6;
  text-align: center;
  vertical-align: middle;
  margin-right: 10px;
  margin-left: 5px;
}
#jobListings #jobResults .jobIcon .bi::before {
  line-height: 2 !important;
}
#jobListings #jobResults .jobHighNeedsIcon {
  width: 32px;
  height: 32px;
  border-radius: 5px;
  border: 0px solid;
  /*background-color: #f6b341;*/
  color: #f6b341;
  text-align: center;
  vertical-align: middle;
  margin-right: 10px;
  margin-left: 5px;
}
#jobListings #jobResults .jobHighNeedsIcon .bi::before {
  line-height: 2 !important;
}
#jobListings #jobResults .jobNewJobsIcon {
  width: 32px;
  height: 32px;
  border-radius: 5px;
  border: 0px solid;
  /*background-color: #0de212;*/
  color: #0de212;
  text-align: center;
  vertical-align: middle;
  margin-right: 10px;
  margin-left: 5px;
}
#jobListings #jobResults .jobNewJobsIcon .bi::before {
  line-height: 2 !important;
}
#jobListings #jobResults .jobNewJobsIcon .totalJobsIcon {
  color: #3478f6 !important;
  padding: 5px;
}
#jobListings #jobResults .jobNewJobsIcon .newJobsIcon {
  color: #0de212 !important;
  padding: 5px;
}
#jobListings #jobResults .card {
  background-color: transparent !important;
  color: #ffffff;
  border-color: #6c757d !important;
}
#jobListings #jobResults .card .card-top {
  background-color: #333333;
  border-radius: 0px;
  overflow: hidden;
}
#jobListings #jobResults .card .card-top .bi {
  color: #DE7542 !important;
}
#jobListings #jobResults .card .card-top h6 {
  color: #ffffff;
}
#jobListings #jobResults .card p {
  color: #ffffff;
}
#jobListings #jobResults .card .card-body {
  background-color: transparent !important;
  color: #ffffff;
}
#jobListings #jobResults .card .totalJobsIcon {
  color: #3478f6 !important;
  padding: 5px;
}
#jobListings #jobResults .card .newJobsIcon {
  color: #0de212 !important;
  padding: 5px;
}
#jobListings #jobResults .card .card-btn {
  border: solid 1px #DE7542;
  background-color: #DE7542;
  color: #ffffff;
}
#jobListings #jobResults .card .card-btn:hover {
  background-color: #ffffff;
  color: #DE7542;
}
#jobListings .modal-title {
  color: #DE7542;
}

#jobSyncSheet {
  color: #000000;
  /* w3 modal */
}
#jobSyncSheet .btn-save {
  background-color: #3479f6 !important;
  color: #ffffff;
}
#jobSyncSheet .btn-save:hover {
  background-color: red !important;
}
#jobSyncSheet h2 {
  color: #af3c3c;
}
#jobSyncSheet p {
  color: #000000;
}
#jobSyncSheet .jobClosed {
  color: #666666 !important;
}
#jobSyncSheet .jobClosedRecent {
  color: lightcoral !important;
}
#jobSyncSheet .jobHighNeeds {
  color: red !important;
}
#jobSyncSheet .jobUrgent {
  color: red !important;
}
#jobSyncSheet .jobStandard {
  color: #000000 !important;
}
#jobSyncSheet .jobAttention {
  color: darkorange !important;
}
#jobSyncSheet .jobUpdate {
  color: green !important;
}
#jobSyncSheet .advertiseJob {
  color: green !important;
}
#jobSyncSheet .advertiseJobNo {
  color: red !important;
}
#jobSyncSheet .jobOpen {
  color: green !important;
  font-size: 0.8rem;
  font-style: normal;
}
#jobSyncSheet .jobClosed {
  color: red !important;
  font-size: 0.8rem;
  font-style: normal;
}
#jobSyncSheet .alertTop {
  top: 125px;
  z-index: 9999;
}
#jobSyncSheet #jobSync {
  font-size: 0.75rem !important;
  color: #000000;
}
#jobSyncSheet #jobSync th {
  vertical-align: middle;
  padding: 20px 15px 20px 15px;
  text-align: center;
}
#jobSyncSheet #jobSync thead {
  border-bottom: 1px solid #666666;
}
#jobSyncSheet #jobSync td {
  text-align: center;
  padding: 15px;
  vertical-align: middle;
}
#jobSyncSheet #jobSync td h4 {
  margin-bottom: 0px;
  color: #000000;
}
#jobSyncSheet #jobSync td h4 i {
  color: #000000;
}
#jobSyncSheet #jobSync td .campaignField {
  width: 150px;
}
#jobSyncSheet #jobSync td .campaignField .form-select {
  font-size: 0.75rem;
  background-image: url("../images/caret.svg");
}
#jobSyncSheet #jobSync td .radiusField {
  width: 95px;
}
#jobSyncSheet #jobSync td .radiusField .form-control {
  font-size: 0.75rem;
}
#jobSyncSheet .modal {
  color: #000000;
}
#jobSyncSheet .modal p {
  color: #000000;
}
#jobSyncSheet .modal li {
  color: #000000;
}
#jobSyncSheet .modal .form-check-input:checked {
  background-color: green;
  border-color: green;
}
#jobSyncSheet .modal .form-switch .form-check-input {
  width: 3.5em;
}
#jobSyncSheet .w3-container {
  color: #000000 !important;
}
#jobSyncSheet .w3-container p {
  color: #000000;
}
#jobSyncSheet .w3-container li {
  color: #000000;
}
#jobSyncSheet .w3-container .form-check-input:checked {
  background-color: green;
  border-color: green;
}
#jobSyncSheet .w3-container .form-switch .form-check-input {
  width: 3.5em;
}
#jobSyncSheet #geoSync {
  background: transparent;
}
#jobSyncSheet #geoSync .accordion-item {
  background-color: transparent;
  border: solid #2d2f34 1px;
  border-bottom: solid #666666 1px;
}
#jobSyncSheet #geoSync .accordion-body {
  border-top: solid #af3c3c 2px;
}
#jobSyncSheet #geoSync .accordion-button {
  background-color: #222429;
}
#jobSyncSheet #geoSync .accordion-button h4 {
  color: #ffffff;
}
#jobSyncSheet #geoSync i {
  color: orange;
}
#jobSyncSheet #geoSync h4 {
  color: #000000;
}

#quickEdit .wrapper {
  border: 1px solid dimgrey;
  border-radius: 15px;
}
#quickEdit h2 {
  color: #af3c3c;
}
#quickEdit h4 {
  color: #000000;
}
#quickEdit h5 {
  color: #000000;
}
#quickEdit .form-select {
  background-image: url("../images/caret.svg") !important;
}
#quickEdit .form-control {
  background-color: transparent;
  color: #000000;
}
#quickEdit .form-switch .form-check-input {
  width: 3em;
  height: 2em;
  border: none;
}
#quickEdit .form-check-label {
  color: #000000;
}
#quickEdit .form-check-input:checked {
  background-color: #0de212;
  border-color: #0d6efd !important;
}

.accordion-button:not(.collapsed)::after {
  background-image: url("../images/caret.svg");
  transform: rotate(-180deg);
}

.accordion-button::after {
  content: "";
  background-image: url("../images/caret.svg") !important;
}

.homeForm .homeForm__inner {
  background: none;
}
.homeForm .homeForm__inner label {
  color: #ffffff;
}
.homeForm .homeForm__inner .fui-input, .homeForm .homeForm__inner .fui-select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: rgba(0, 0, 0, 0.8) !important;
  color: #ffffff !important;
}
.homeForm .homeForm__inner .fui-submit {
  background-color: #DE7542 !important;
  border-color: #DE7542;
  color: #ffffff;
}
.homeForm .homeForm__inner .fui-label, .homeForm .homeForm__inner .fui-legend {
  color: #ffffff;
}
.homeForm .homeForm__inner .fui-checkbox input:checked + label:before, .homeForm .homeForm__inner .fui-radio input:checked + label:before {
  background-color: #DE7542;
  border-color: #DE7542;
}
.homeForm .homeForm__inner .fui-field {
  flex: 1 !important;
}

.pageForm {
  /* formie */
  background-color: none;
}
.pageForm h3 {
  color: #D3313A !important;
}
.pageForm .fui-input, .pageForm .fui-select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: rgba(0, 0, 0, 0.8) !important;
  color: #ffffff !important;
}
.pageForm .fui-submit {
  background-color: #DE7542 !important;
  color: #ffffff !important;
  border-color: #DE7542;
}
.pageForm .fui-label, .pageForm .fui-legend {
  color: #ffffff;
}
.pageForm .fui-checkbox input:checked + label:before, .pageForm .fui-radio input:checked + label:before {
  background-color: #DE7542;
  border-color: #DE7542;
  color: #ffffff !important;
}
.pageForm .fui-color {
  color: #ffffff;
}

.blogWrapper {
  .background-color: #ffffff;
  /* blog index page cards */
  /* blog entry page */
}
.blogWrapper .album .card {
  background-color: #ffffff;
}
.blogWrapper .album .card img {
  width: 100%;
  height: auto;
}
.blogWrapper .album .card .card-body {
  color: #393939;
}
.blogWrapper .album .card .card-body h4 {
  color: #393939;
}
.blogWrapper .blogContainer {
  .background-color: #ffffff;
}
.blogWrapper .blogContainer .blogContent {
  color: #393939;
}
.blogWrapper .blogContainer .blogContent h1, .blogWrapper .blogContainer .blogContent h2, .blogWrapper .blogContainer .blogContent h3, .blogWrapper .blogContainer .blogContent h4 {
  color: #393939;
  text-align: center;
}
.blogWrapper .blogContainer .blogContent p {
  color: #393939;
}
.blogWrapper .blogContainer .blogImage img {
  width: 100%;
  height: auto;
}

#exitModal .modal-dialog .modal-content .modal-body h5 {
  color: #DE7542;
}
#exitModal .modal-dialog .modal-content .modal-body .decline-modal-btn {
  border: solid 1px #D3313A;
  color: #D3313A;
}
#exitModal .modal-dialog .modal-content .modal-body .decline-modal-btn:hover {
  background-color: #ffffff;
  border: solid 2px #D3313A;
  color: #D3313A;
}