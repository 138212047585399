#jobSyncSheet {
  color: $black;
  .btn-save {
    background-color: #3479f6 !important;
    color: #ffffff;
  }
  .btn-save:hover {
    background-color: red !important;
  }
  h2 {
    color: #af3c3c;
  }
  p {
    color: $black;
  }
  .jobClosed {
    color: #666666 !important;
  }
  .jobClosedRecent {
    color: lightcoral !important;
  }
  .jobHighNeeds {
    color: red !important;
  }
  .jobUrgent {
    color: red !important;
  }
  .jobStandard {
    color: $black !important;
  }
  .jobAttention {
    color: darkorange !important;
  }
  .jobUpdate {
    color: green !important;
  }
  .advertiseJob {
    color: green !important;
  }
  .advertiseJobNo {
    color: red !important;
  }
  .jobOpen {
    color: green !important;
    font-size: .8rem;
    font-style: normal;
  }
  .jobClosed {
    color: red !important;
    font-size: .8rem;
    font-style: normal;
  }
  .alertTop{
    top: 125px;
    z-index: 9999;
  }

  #jobSync {
    font-size: .75rem !important;
    color: $black;

    th {
      vertical-align: middle;
      padding: 20px 15px 20px 15px;
      text-align: center;
    }
    thead {
      border-bottom: 1px solid #666666;
    }

    td {
      text-align: center;
      padding: 15px;
      vertical-align: middle;
      h4 {
        margin-bottom: 0px;
        color: $black;
       i {
         color: $black;
       }
      }
      .campaignField {
        width: 150px;
        .form-select {
          font-size: .75rem;
          //background-color: transparent;
          //color: #ffffff;
          background-image: url("../images/caret.svg");
        }
      }
      .radiusField {
        width: 95px;
        .form-control {
          font-size: .75rem;
          //background-color: transparent;
          //color: #ffffff;
        }
      }
    }
  }
  .modal {
    color: #000000;
    p {
      color: #000000;
    }
    li {
      color: #000000
    }
    .form-check-input:checked {
      background-color: green;
      border-color: green;
    }
    .form-switch .form-check-input {
      width: 3.5em;
    }

  }
  /* w3 modal */
  .w3-container {
    color: #000000 !important;
    p {
      color: #000000;
    }
    li {
      color: #000000
    }
    .form-check-input:checked {
      background-color: green;
      border-color: green;
    }
    .form-switch .form-check-input {
      width: 3.5em;
    }
  }
  #geoSync {
    background: transparent;
    .accordion-item {
      background-color: transparent;
      border: solid #2d2f34 1px;
      border-bottom: solid #666666 1px;
    }
    .accordion-body {
      border-top: solid #af3c3c 2px;
    }
    .accordion-button {
      background-color: #222429;
      h4 {
        color: $white;
      }
    }
    i {
      color: orange;
    }
    h4 {
      color: $black;
    }

  }

}

#quickEdit {
  .wrapper {
    border: 1px solid dimgrey;
    border-radius: 15px;
  }
  h2 {
    color: #af3c3c;
  }
  h4 {
    color: $black;
  }
  h5 {
    color: $black;
  }
  .form-select {
  //background-color: transparent;
  //color: #ffffff;
  background-image: url("../images/caret.svg") !important;
}
  .form-control {
    background-color: transparent;
    color: $black;
  }
  .form-switch .form-check-input {
    width: 3em;
    height: 2em;
    border: none;
  }
  .form-check-label {
    color: $black;
  }
  .form-check-input:checked {
    background-color: #0de212;
    border-color: #0d6efd !important;
  }
}

.accordion-button:not(.collapsed)::after {
  background-image: url("../images/caret.svg");
  transform: rotate(-180deg);
}
.accordion-button::after {
  content: "";
  background-image: url("../images/caret.svg") !important;
}