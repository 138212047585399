#exitModal{
  .modal-dialog{
    .modal-content{
      .modal-body{
        h5{
          color: $accent-color-1;
        }
        .decline-modal-btn{
          border: solid 1px $accent-color-2;
          color: $accent-color-2;
        }
        .decline-modal-btn:hover{
          background-color: $white;
          border: solid 2px $accent-color-2;
          color: $accent-color-2;
        }

        }
    }
  }
}