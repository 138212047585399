/* landing page dark */
#landingPageDark {
  header {
    background-color: $header-bg;
    .header-image {
      background-image: url("../images/page-bg2.jpg");
      min-height: 300px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: right center;
    }
    @media screen and (max-width: 1200px){
      .header-image{
        min-height: 285px;
      }
    }
    @media screen and (max-width: 992px){
      .header-image{
        min-height: 210px;
      }
    }
    .headline {
      h1 {
        font-family: 'Montserrat', sans-serif;
        font-weight: 800;
        font-style: italic;
        color: $header-h1;
      }
      h4{
        color: $header-h4;
        .btn-link{
          background-color: $header-bg !important;
          color: $header-icon-color;
          .bi{
            color: $header-icon-color;
          }
        }
      }

      .play-btn{
        background-color: $header-bg !important;
        color: $header-button-bg !important;
      }

      .btn{
        background-color: $header-button-bg;
        color:$white;
      }
      .btn-reverse{
        background: inherit !important;
      }
    }
  }
  @media screen and (max-width: 992px){
    header{
      margin-top: 70px;
    }
  }
  article{
    background: $background-gradient;
    color: $white;
    ul {
      color: $white;
    }
    li {
      color: $white;
    }
    h3{
      color: $accent-color-2;
    }
    p {
      color: $article-text-color;
    }

    #jobIds {
      p {
        color: $white;
        margin-bottom: 0px;
      }
    }

  }
  //Application Form on the page on the side
  //Please refer to the Form.scss File in the partials for the sidebar form
}